import 'public/src/pages/common/cookie_id'
import 'public/src/services/abt'

gbCommonInfo.user = window.gbCheckoutSsrData.user || {}



appEventCenter.on('pageOnload', () => {
  try {
    if(window.SaPageInfo) {
      sa('set', 'setPageData', window.SaPageInfo)
    }
    sa('send', 'pageEnter', { start_time: Date.now() })
  } catch (e) { console.log(e) }
})
